import React from "react";

// Creates an input field with a label and a type

const InputField = ({ label, type, id, value, onChange, required }) => (
  <div className="input-group">
    <label htmlFor={id}>
      {label} <span style={{ color: "red" }}>*</span>
    </label>
    <input
      required={required}
      type={type}
      id={id}
      value={value}
      onChange={onChange}
      maxLength={100}
    />
  </div>
);

export default InputField;
