import React, { useState, useRef, useEffect } from "react";
import "./DeclaForm.css";
import axios from "axios";
import jspdf from "jspdf";
import "jspdf-autotable";
import { categoryOptions } from "../../data/categories";
//import { companyRecipientOptions } from "../../data/companyRecipients";
import FileInputField from "../InputField/FileInputField";
import SelectedFiles from "../InputField/SelectedFiles";
import InputField from "../InputField/InputField";
import SelectField from "../InputField/SelectField";
import { loginRequest } from "../../authConfig";
import { callMsGraph } from "../../graph";
import { useMsal } from "@azure/msal-react";

const dateToday = new Date().toLocaleDateString();

const currentTime = new Date();
const currentHours = currentTime.getHours().toString().padStart(2, '0');
const currentMinutes = currentTime.getMinutes().toString().padStart(2, '0');
const currentTimeString = `${currentHours}:${currentMinutes}`;


const useGraphData = () => {
  const { instance, accounts } = useMsal();
  const [userName, setUserName] = useState(null);

  const fetchGraphData = async () => {
    const response = await instance.acquireTokenSilent({
      ...loginRequest,
      account: accounts[0],
    });

    const data = await callMsGraph(response.accessToken);
    setUserName(data.displayName);
  };

  useEffect(() => {
    fetchGraphData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Empty dependency array means this effect runs once on mount

  return { userName };
};

function DeclaForm() {
  const { userName } = useGraphData();
  const [name, setName] = useState("");
  const [bankAccountNumber, setBankAccountNumber] = useState("");
  //  const [business, setBusiness] = useState("");
  const [description1, setDescription1] = useState("");
  const [description2, setDescription2] = useState("");
  const [description3, setDescription3] = useState("");
  const [description4, setDescription4] = useState("");
  const [description5, setDescription5] = useState("");
  const [description6, setDescription6] = useState("");
  const [description7, setDescription7] = useState("");
  const [description8, setDescription8] = useState("");
  const [description9, setDescription9] = useState("");
  let [totalAmountWithoutTaxes1, setTotalAmountWithoutTaxes1] = useState("");
  let [totalAmountWithoutTaxes2, setTotalAmountWithoutTaxes2] = useState("");
  let [totalAmountWithoutTaxes3, setTotalAmountWithoutTaxes3] = useState("");
  let [totalAmountWithoutTaxes4, setTotalAmountWithoutTaxes4] = useState("");
  let [totalAmountWithoutTaxes5, setTotalAmountWithoutTaxes5] = useState("");
  let [totalAmountWithoutTaxes6, setTotalAmountWithoutTaxes6] = useState("");
  let [totalAmountWithoutTaxes7, setTotalAmountWithoutTaxes7] = useState("");
  let [totalAmountWithoutTaxes8, setTotalAmountWithoutTaxes8] = useState("");
  let [totalAmountWithoutTaxes9, setTotalAmountWithoutTaxes9] = useState("");
  let [taxAmount1, setTaxAmount1] = useState("");
  let [taxAmount2, setTaxAmount2] = useState("");
  let [taxAmount3, setTaxAmount3] = useState("");
  let [taxAmount4, setTaxAmount4] = useState("");
  let [taxAmount5, setTaxAmount5] = useState("");
  let [taxAmount6, setTaxAmount6] = useState("");
  let [taxAmount7, setTaxAmount7] = useState("");
  let [taxAmount8, setTaxAmount8] = useState("");
  let [taxAmount9, setTaxAmount9] = useState("");
  let [category1, setCategory1] = useState("");
  let [category2, setCategory2] = useState("");
  let [category3, setCategory3] = useState("");
  let [category4, setCategory4] = useState("");
  let [category5, setCategory5] = useState("");
  let [category6, setCategory6] = useState("");
  let [category7, setCategory7] = useState("");
  let [category8, setCategory8] = useState("");
  let [category9, setCategory9] = useState("");
  const fileInput = useRef();
  const [selectedFiles, setSelectedFiles] = useState([]);
  let categorySelectRef1 = useRef();
  let categorySelectRef2 = useRef();
  let categorySelectRef3 = useRef();
  let categorySelectRef4 = useRef();
  let categorySelectRef5 = useRef();
  let categorySelectRef6 = useRef();
  let categorySelectRef7 = useRef();
  let categorySelectRef8 = useRef();
  let categorySelectRef9 = useRef();
  //  const businessSelectRef = useRef();
  const [displayStep, setDisplayStep] = useState(0);
  const voegPostToe = () => {
    setDisplayStep((prevStep) => prevStep + 1);
  };
  const haalPostWeg = () => {
    setDisplayStep((prevStep) => prevStep - 1);
  };

  useEffect(() => {
    if (userName) {
      setName(userName);
    }
  }, [userName]);

  const generatePDF = (
    totalAmount,
    totalAmount1,
    totalAmount2,
    totalAmount3,
    totalAmount4,
    totalAmount5,
    totalAmount6,
    totalAmount7,
    totalAmount8,
    totalAmount9,
  ) => {
    const doc = new jspdf({
      orientation: "landscape",
      unit: "mm", // of 'pt', 'px', 'cm'
      format: "a4", // of ander formaat naar keuze
    });

    let categoryName1 =
      categorySelectRef1.current.options[
        categorySelectRef1.current.selectedIndex
      ].text;
    let categoryName2 = "";
    if (displayStep >= 1 && categorySelectRef2.current) {
      categoryName2 =
        categorySelectRef2.current.options[
          categorySelectRef2.current.selectedIndex
        ].text;
    }
    let categoryName3 = "";
    if (displayStep >= 2 && categorySelectRef3.current) {
      categoryName3 =
        categorySelectRef3.current.options[
          categorySelectRef3.current.selectedIndex
        ].text;
    }
    let categoryName4 = "";
    if (displayStep >= 3 && categorySelectRef4.current) {
      categoryName4 =
        categorySelectRef4.current.options[
          categorySelectRef4.current.selectedIndex
        ].text;
    }
    let categoryName5 = "";
    if (displayStep >= 4 && categorySelectRef5.current) {
      categoryName5 =
        categorySelectRef5.current.options[
          categorySelectRef5.current.selectedIndex
        ].text;
    }
    let categoryName6 = "";
    if (displayStep >= 5 && categorySelectRef6.current) {
      categoryName6 =
        categorySelectRef6.current.options[
          categorySelectRef6.current.selectedIndex
        ].text;
    }
    let categoryName7 = "";
    if (displayStep >= 6 && categorySelectRef7.current) {
      categoryName7 =
        categorySelectRef7.current.options[
          categorySelectRef7.current.selectedIndex
        ].text;
    }
    let categoryName8 = "";
    if (displayStep >= 7 && categorySelectRef8.current) {
      categoryName8 =
        categorySelectRef8.current.options[
          categorySelectRef8.current.selectedIndex
        ].text;
    }
    let categoryName9 = "";
    if (displayStep >= 8 && categorySelectRef9.current) {
      categoryName9 =
        categorySelectRef9.current.options[
          categorySelectRef9.current.selectedIndex
        ].text;
    }

    doc.setFontSize(10);
    doc.text(`Naam declarant: ${name}`, 15, 10);
    doc.text(`Bankrekeningnummer: ${bankAccountNumber}`, 15, 15);
    doc.text(`Declaratienummer: ${name}-${dateToday}-${currentTimeString}`, 15, 20);
    doc.text(`Declaratie omschrijving: ${name}-${dateToday}-${currentTimeString}`, 15, 25);
    doc.text(
      `Totaalbedrag van deze declaratie: € ${totalAmount.toLocaleString("nl-NL")}`,
      15,
      30,
    );

    const headers = [
      [
        "Datum",
        "Omschrijving",
        "Categoriecode",
        "Categorienaam",
        "Bedrag (excl. BTW)",
        "BTW bedrag",
        "Totaalbedrag",
      ],
    ];

    const data = [
      [
        dateToday,
        description1,
        category1,
        categoryName1.substring(0, 20),
        "€ " + totalAmountWithoutTaxes1.toLocaleString("nl-NL"),
        "€ " + taxAmount1.toLocaleString("nl-NL"),
        "€ " + totalAmount1.toLocaleString("nl-NL"),
      ],
    ];
    if (displayStep >= 1) {
      data.push([
        dateToday,
        description2,
        category2,
        categoryName2.substring(0, 20),
        "€ " + totalAmountWithoutTaxes2.toLocaleString("nl-NL"),
        "€ " + taxAmount2.toLocaleString("nl-NL"),
        "€ " + totalAmount2.toLocaleString("nl-NL"),
      ]);
    }
    if (displayStep >= 2) {
      data.push([
        dateToday,
        description3,
        category3,
        categoryName3.substring(0, 20),
        "€ " + totalAmountWithoutTaxes3.toLocaleString("nl-NL"),
        "€ " + taxAmount3.toLocaleString("nl-NL"),
        "€ " + totalAmount3.toLocaleString("nl-NL"),
      ]);
    }
    if (displayStep >= 3) {
      data.push([
        dateToday,
        description4,
        category4,
        categoryName4.substring(0, 20),
        "€ " + totalAmountWithoutTaxes4.toLocaleString("nl-NL"),
        "€ " + taxAmount4.toLocaleString("nl-NL"),
        "€ " + totalAmount4.toLocaleString("nl-NL"),
      ]);
    }
    if (displayStep >= 4) {
      data.push([
        dateToday,
        description5,
        category5,
        categoryName5.substring(0, 20),
        "€ " + totalAmountWithoutTaxes5.toLocaleString("nl-NL"),
        "€ " + taxAmount5.toLocaleString("nl-NL"),
        "€ " + totalAmount5.toLocaleString("nl-NL"),
      ]);
    }
    if (displayStep >= 5) {
      data.push([
        dateToday,
        description6,
        category6,
        categoryName6.substring(0, 20),
        "€ " + totalAmountWithoutTaxes6.toLocaleString("nl-NL"),
        "€ " + taxAmount6.toLocaleString("nl-NL"),
        "€ " + totalAmount6.toLocaleString("nl-NL"),
      ]);
    }
    if (displayStep >= 6) {
      data.push([
        dateToday,
        description7,
        category7,
        categoryName7.substring(0, 20),
        "€ " + totalAmountWithoutTaxes7.toLocaleString("nl-NL"),
        "€ " + taxAmount7.toLocaleString("nl-NL"),
        "€ " + totalAmount7.toLocaleString("nl-NL"),
      ]);
    }
    if (displayStep >= 7) {
      data.push([
        dateToday,
        description8,
        category8,
        categoryName8.substring(0, 20),
        "€ " + totalAmountWithoutTaxes8.toLocaleString("nl-NL"),
        "€ " + taxAmount8.toLocaleString("nl-NL"),
        "€ " + totalAmount8.toLocaleString("nl-NL"),
      ]);
    }
    if (displayStep >= 8) {
      data.push([
        dateToday,
        description9,
        category9,
        categoryName9.substring(0, 20),
        "€ " + totalAmountWithoutTaxes9.toLocaleString("nl-NL"),
        "€ " + taxAmount9.toLocaleString("nl-NL"),
        "€ " + totalAmount9.toLocaleString("nl-NL"),
      ]);
    }

    doc.autoTable({
      head: headers,
      body: data,
      startY: 35, // Start the table lower to make room for the name and bank account number
      styles: { cellWidth: "wrap", cellPadding: 1, fontSize: 8 },
      tableWidth: "auto",
      setFontSize: 10,
    });

    const pdfData = doc.output("blob");

    return pdfData;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let newTab = null;

    if (selectedFiles.length === 0) {
      alert("Upload alstublieft minstens één bestand.");
      fileInput.current.focus();
      return;
    }

    if (category1 !== "4505" && taxAmount1 === "") {
      if (
        window.confirm(
          "Het BTW bedrag van post 1 is niet ingevuld. Wilt u toch doorgaan?",
        )
      ) {
      } else return;
    }
    if (category2 !== "4505" && displayStep >= 1 && taxAmount2 === "") {
      if (
        window.confirm(
          "Het BTW bedrag van post 2 is niet ingevuld. Wilt u toch doorgaan?",
        )
      ) {
      } else return;
    }
    if (category3 !== "4505" && displayStep >= 2 && taxAmount3 === "") {
      if (
        window.confirm(
          "Het BTW bedrag van post 3 is niet ingevuld. Wilt u toch doorgaan?",
        )
      ) {
      } else return;
    }
    if (category4 !== "4505" && displayStep >= 3 && taxAmount4 === "") {
      if (
        window.confirm(
          "Het BTW bedrag van post 4 is niet ingevuld. Wilt u toch doorgaan?",
        )
      ) {
      } else return;
    }
    if (category5 !== "4505" && displayStep >= 4 && taxAmount5 === "") {
      if (
        window.confirm(
          "Het BTW bedrag van post 5 is niet ingevuld. Wilt u toch doorgaan?",
        )
      ) {
      } else return;
    }
    if (category6 !== "4505" && displayStep >= 5 && taxAmount6 === "") {
      if (
        window.confirm(
          "Het BTW bedrag van post 6 is niet ingevuld. Wilt u toch doorgaan?",
        )
      ) {
      } else return;
    }
    if (category7 !== "4505" && displayStep >= 6 && taxAmount7 === "") {
      if (
        window.confirm(
          "Het BTW bedrag van post 7 is niet ingevuld. Wilt u toch doorgaan?",
        )
      ) {
      } else return;
    }
    if (category8 !== "4505" && displayStep >= 7 && taxAmount8 === "") {
      if (
        window.confirm(
          "Het BTW bedrag van post 8 is niet ingevuld. Wilt u toch doorgaan?",
        )
      ) {
      } else return;
    }
    if (category9 !== "4505" && displayStep >= 8 && taxAmount9 === "") {
      if (
        window.confirm(
          "Het BTW bedrag van post 9 is niet ingevuld. Wilt u toch doorgaan?",
        )
      ) {
      } else return;
    }

    let totalAmount1 = Number(totalAmountWithoutTaxes1) + Number(taxAmount1);
    let totalAmount2 = 0;
    if (displayStep >= 1) {
      totalAmount2 = Number(totalAmountWithoutTaxes2) + Number(taxAmount2);
    }
    let totalAmount3 = 0;
    if (displayStep >= 2) {
      totalAmount3 = Number(totalAmountWithoutTaxes3) + Number(taxAmount3);
    }
    let totalAmount4 = 0;
    if (displayStep >= 3) {
      totalAmount4 = Number(totalAmountWithoutTaxes4) + Number(taxAmount4);
    }
    let totalAmount5 = 0;
    if (displayStep >= 4) {
      totalAmount5 = Number(totalAmountWithoutTaxes5) + Number(taxAmount5);
    }
    let totalAmount6 = 0;
    if (displayStep >= 5) {
      totalAmount6 = Number(totalAmountWithoutTaxes6) + Number(taxAmount6);
    }
    let totalAmount7 = 0;
    if (displayStep >= 6) {
      totalAmount7 = Number(totalAmountWithoutTaxes7) + Number(taxAmount7);
    }
    let totalAmount8 = 0;
    if (displayStep >= 7) {
      totalAmount8 = Number(totalAmountWithoutTaxes8) + Number(taxAmount8);
    }
    let totalAmount9 = 0;
    if (displayStep >= 8) {
      totalAmount9 = Number(totalAmountWithoutTaxes9) + Number(taxAmount9);
    }
    let totalAmount =
      Number(totalAmount1) +
      Number(totalAmount2) +
      Number(totalAmount3) +
      Number(totalAmount4) +
      Number(totalAmount5) +
      Number(totalAmount6) +
      Number(totalAmount7) +
      Number(totalAmount8) +
      Number(totalAmount9);

    const form = new FormData();
    form.append("from", "Decla App <declaapp@newspark.nl>");
    form.append("to", `newspark <rp@newspark.nl>`);
    form.append("subject", `Declaratie van ${name} - ${dateToday} - ${currentTimeString}`);
    form.append(
      "text",
      "Beste Rowald,\n\n" +
      "Hier weer een declaratie gemaakt met de decla-app, opgesteld door " +
      name +
      " van Newspark.\n\n" +
      "Het totaalbedrag van deze decla is: € " +
      totalAmount.toLocaleString("nl-NL") +
      ".\nDit kan worden uitgekeerd naar rekeningnr: " +
      bankAccountNumber +
      "\n\nVragen? neem contact op met " +
      name +
      " of met de bouwers van de decla-app.\n\nMvg,\n\nDecla-app",
    );

    Array.from(fileInput.current.files).forEach((file) => {
      form.append("attachment", file);
    });

    const wantsCopy = window.confirm("Wilt u een kopie van de PDF openen?");

    if (wantsCopy) {
      newTab = window.open("", "_blank");
    }

    const pdfData = generatePDF(
      totalAmount,
      totalAmount1,
      totalAmount2,
      totalAmount3,
      totalAmount4,
      totalAmount5,
      totalAmount6,
      totalAmount7,
      totalAmount8,
      totalAmount9,
    );
    const fileName = `Declaratie van ${name} - ${dateToday} - ${currentTimeString}.pdf`;
    form.append("attachment", pdfData, fileName);

    axios
      .post(
        "https://api.mailgun.net/v3/sandbox1d23fd0adedd448b8a08a0223004401a.mailgun.org/messages",
        form,
        {
          auth: {
            username: "api",
            password: "885d08aa32450a327ecd0321670756ac-4b670513-d97c74e8",
          },
        },
      )
      .then((response) => {
        console.log("Email sent successfully");
        alert("Declaratie succesvol verzonden");

        if (wantsCopy && newTab) {
          const url = URL.createObjectURL(pdfData);
          newTab.location.href = url;
        }

        setDescription1("");
        setDescription2("");
        setDescription3("");
        setDescription4("");
        setDescription5("");
        setDescription6("");
        setDescription7("");
        setDescription8("");
        setDescription9("");
        setCategory1("");
        setCategory2("");
        setCategory3("");
        setCategory4("");
        setCategory5("");
        setCategory6("");
        setCategory7("");
        setCategory8("");
        setCategory9("");
        setTotalAmountWithoutTaxes1("");
        setTotalAmountWithoutTaxes2("");
        setTotalAmountWithoutTaxes3("");
        setTotalAmountWithoutTaxes4("");
        setTotalAmountWithoutTaxes5("");
        setTotalAmountWithoutTaxes6("");
        setTotalAmountWithoutTaxes7("");
        setTotalAmountWithoutTaxes8("");
        setTotalAmountWithoutTaxes9("");
        setTaxAmount1("");
        setTaxAmount2("");
        setTaxAmount3("");
        setTaxAmount4("");
        setTaxAmount5("");
        setTaxAmount6("");
        setTaxAmount7("");
        setTaxAmount8("");
        setTaxAmount9("");
        setSelectedFiles([]);
      })
      .catch((error) => {
        console.error("There was an error sending the email", error);
        alert(error.message);
      });
  };

  const handleFileChange = (e) => {
    setSelectedFiles(Array.from(e.target.files));
  };

  return (
    <div>
      <h2>Declaratie Formulier</h2>
      <form onSubmit={handleSubmit}>
        <InputField
          label="Naam declarant"
          type="text"
          id="name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />
        <InputField
          label="Bankrekeningnummer"
          type="text"
          id="bankAccountNumber"
          value={bankAccountNumber}
          onChange={(e) => setBankAccountNumber(e.target.value)}
          required
        />

        <FileInputField
          id="fileInput"
          fileInput={fileInput}
          handleFileChange={handleFileChange}
        />
        <SelectedFiles selectedFiles={selectedFiles} />

        <h3>Post 1</h3>
        <InputField
          label="Omschrijving"
          type="text"
          id="description1"
          value={description1}
          onChange={(e) => setDescription1(e.target.value)}
          required
        />
        <SelectField
          label="Categorie"
          id="category1"
          value={category1}
          onChange={(e) => setCategory1(e.target.value)}
          required
          options={categoryOptions}
          ref={categorySelectRef1}
        />
        <InputField
          label="Bedrag (excl. BTW)"
          type="number"
          id="totalAmountWithoutTaxes1"
          value={totalAmountWithoutTaxes1.toLocaleString("nl-NL")}
          onChange={(e) => setTotalAmountWithoutTaxes1(e.target.value)}
          required
        />
        <InputField
          label="BTW bedrag"
          type="number"
          id="taxAmount1"
          value={taxAmount1.toLocaleString("nl-NL")}
          onChange={(e) => setTaxAmount1(e.target.value)}
        />

        {/* Conditioneel gerenderde velden voor Post 2 */}
        {displayStep >= 1 && (
          <>
            <h3>Post 2</h3>
            <InputField
              label="Omschrijving"
              type="text"
              id="description2"
              value={description2}
              onChange={(e) => setDescription2(e.target.value)}
              required={displayStep >= 1}
            />
            <SelectField
              label="Categorie"
              id="category2"
              value={category2}
              onChange={(e) => setCategory2(e.target.value)}
              required={displayStep >= 1}
              options={categoryOptions}
              ref={categorySelectRef2}
            />
            <InputField
              label="Bedrag (excl. BTW)"
              type="number"
              id="totalAmountWithoutTaxes2"
              value={totalAmountWithoutTaxes2.toLocaleString("nl-NL")}
              onChange={(e) => setTotalAmountWithoutTaxes2(e.target.value)}
              required={displayStep >= 1}
            />
            <InputField
              label="BTW bedrag"
              type="number"
              id="taxAmount2"
              value={taxAmount2.toLocaleString("nl-NL")}
              onChange={(e) => setTaxAmount2(e.target.value)}
            />
          </>
        )}
        {/* Conditioneel gerenderde velden voor Post 3 */}
        {displayStep >= 2 && (
          <>
            <h3>Post 3</h3>
            <InputField
              label="Omschrijving"
              type="text"
              id="description3"
              value={description3}
              onChange={(e) => setDescription3(e.target.value)}
              required={displayStep >= 2}
            />
            <SelectField
              label="Categorie"
              id="category3"
              value={category3}
              onChange={(e) => setCategory3(e.target.value)}
              required={displayStep >= 2}
              options={categoryOptions}
              ref={categorySelectRef3}
            />
            <InputField
              label="Bedrag (excl. BTW)"
              type="number"
              id="totalAmountWithoutTaxes3"
              value={totalAmountWithoutTaxes3.toLocaleString("nl-NL")}
              onChange={(e) => setTotalAmountWithoutTaxes3(e.target.value)}
              required={displayStep >= 2}
            />
            <InputField
              label="BTW bedrag"
              type="number"
              id="taxAmount3"
              value={taxAmount3.toLocaleString("nl-NL")}
              onChange={(e) => setTaxAmount3(e.target.value)}
            />
          </>
        )}

        {/* Conditioneel gerenderde velden voor Post 4 */}
        {displayStep >= 3 && (
          <>
            <h3>Post 4</h3>
            <InputField
              label="Omschrijving"
              type="text"
              id="description4"
              value={description4}
              onChange={(e) => setDescription4(e.target.value)}
              required={displayStep >= 3}
            />
            <SelectField
              label="Categorie"
              id="category4"
              value={category4}
              onChange={(e) => setCategory4(e.target.value)}
              required={displayStep >= 3}
              options={categoryOptions}
              ref={categorySelectRef4}
            />
            <InputField
              label="Bedrag (excl. BTW)"
              type="number"
              id="totalAmountWithoutTaxes4"
              value={totalAmountWithoutTaxes4.toLocaleString("nl-NL")}
              onChange={(e) => setTotalAmountWithoutTaxes4(e.target.value)}
              required={displayStep >= 3}
            />
            <InputField
              label="BTW bedrag"
              type="number"
              id="taxAmount4"
              value={taxAmount4.toLocaleString("nl-NL")}
              onChange={(e) => setTaxAmount4(e.target.value)}
            />
          </>
        )}
        {/* Conditioneel gerenderde velden voor Post 5 */}
        {displayStep >= 4 && (
          <>
            <h3>Post 5</h3>
            <InputField
              label="Omschrijving"
              type="text"
              id="description5"
              value={description5}
              onChange={(e) => setDescription5(e.target.value)}
              required={displayStep >= 4}
            />
            <SelectField
              label="Categorie"
              id="category5"
              value={category5}
              onChange={(e) => setCategory5(e.target.value)}
              required={displayStep >= 4}
              options={categoryOptions}
              ref={categorySelectRef5}
            />
            <InputField
              label="Bedrag (excl. BTW)"
              type="number"
              id="totalAmountWithoutTaxes5"
              value={totalAmountWithoutTaxes5.toLocaleString("nl-NL")}
              onChange={(e) => setTotalAmountWithoutTaxes5(e.target.value)}
              required={displayStep >= 4}
            />
            <InputField
              label="BTW bedrag"
              type="number"
              id="taxAmount5"
              value={taxAmount5.toLocaleString("nl-NL")}
              onChange={(e) => setTaxAmount5(e.target.value)}
            />
          </>
        )}
        {/* Conditioneel gerenderde velden voor Post 6 */}
        {displayStep >= 5 && (
          <>
            <h3>Post 6</h3>
            <InputField
              label="Omschrijving"
              type="text"
              id="description6"
              value={description6}
              onChange={(e) => setDescription6(e.target.value)}
              required={displayStep >= 5}
            />
            <SelectField
              label="Categorie"
              id="category6"
              value={category6}
              onChange={(e) => setCategory6(e.target.value)}
              required={displayStep >= 5}
              options={categoryOptions}
              ref={categorySelectRef6}
            />
            <InputField
              label="Bedrag (excl. BTW)"
              type="number"
              id="totalAmountWithoutTaxes6"
              value={totalAmountWithoutTaxes6.toLocaleString("nl-NL")}
              onChange={(e) => setTotalAmountWithoutTaxes6(e.target.value)}
              required={displayStep >= 5}
            />
            <InputField
              label="BTW bedrag"
              type="number"
              id="taxAmount6"
              value={taxAmount6.toLocaleString("nl-NL")}
              onChange={(e) => setTaxAmount6(e.target.value)}
            />
          </>
        )}
        {/* Conditioneel gerenderde velden voor Post 7 */}
        {displayStep >= 6 && (
          <>
            <h3>Post 7</h3>
            <InputField
              label="Omschrijving"
              type="text"
              id="description7"
              value={description7}
              onChange={(e) => setDescription7(e.target.value)}
              required={displayStep >= 6}
            />
            <SelectField
              label="Categorie"
              id="category7"
              value={category7}
              onChange={(e) => setCategory7(e.target.value)}
              required={displayStep >= 6}
              options={categoryOptions}
              ref={categorySelectRef7}
            />
            <InputField
              label="Bedrag (excl. BTW)"
              type="number"
              id="totalAmountWithoutTaxes7"
              value={totalAmountWithoutTaxes7.toLocaleString("nl-NL")}
              onChange={(e) => setTotalAmountWithoutTaxes7(e.target.value)}
              required={displayStep >= 6}
            />
            <InputField
              label="BTW bedrag"
              type="number"
              id="taxAmount7"
              value={taxAmount7.toLocaleString("nl-NL")}
              onChange={(e) => setTaxAmount7(e.target.value)}
            />
          </>
        )}
        {/* Conditioneel gerenderde velden voor Post 8 */}
        {displayStep >= 7 && (
          <>
            <h3>Post 8</h3>
            <InputField
              label="Omschrijving"
              type="text"
              id="description8"
              value={description8}
              onChange={(e) => setDescription8(e.target.value)}
              required={displayStep >= 7}
            />
            <SelectField
              label="Categorie"
              id="category8"
              value={category8}
              onChange={(e) => setCategory8(e.target.value)}
              required={displayStep >= 7}
              options={categoryOptions}
              ref={categorySelectRef8}
            />
            <InputField
              label="Bedrag (excl. BTW)"
              type="number"
              id="totalAmountWithoutTaxes8"
              value={totalAmountWithoutTaxes8.toLocaleString("nl-NL")}
              onChange={(e) => setTotalAmountWithoutTaxes8(e.target.value)}
              required={displayStep >= 7}
            />
            <InputField
              label="BTW bedrag"
              type="number"
              id="taxAmount8"
              value={taxAmount8.toLocaleString("nl-NL")}
              onChange={(e) => setTaxAmount8(e.target.value)}
            />
          </>
        )}
        {/* Conditioneel gerenderde velden voor Post 9 */}
        {displayStep >= 8 && (
          <>
            <h3>Post 9</h3>
            <InputField
              label="Omschrijving"
              type="text"
              id="description9"
              value={description9}
              onChange={(e) => setDescription9(e.target.value)}
              required={displayStep >= 8}
            />
            <SelectField
              label="Categorie"
              id="category9"
              value={category9}
              onChange={(e) => setCategory9(e.target.value)}
              required={displayStep >= 8}
              options={categoryOptions}
              ref={categorySelectRef9}
            />
            <InputField
              label="Bedrag (excl. BTW)"
              type="number"
              id="totalAmountWithoutTaxes9"
              value={totalAmountWithoutTaxes9.toLocaleString("nl-NL")}
              onChange={(e) => setTotalAmountWithoutTaxes9(e.target.value)}
              required={displayStep >= 8}
            />
            <InputField
              label="BTW bedrag"
              type="number"
              id="taxAmount9"
              value={taxAmount9.toLocaleString("nl-NL")}
              onChange={(e) => setTaxAmount9(e.target.value)}
            />
          </>
        )}

        {/* Knop om de volgende post te tonen*/}
        <button type="button" onClick={voegPostToe}>
          Voeg extra post toe
        </button>
        {/* Knop om de volgende post te tonen*/}
        <button type="button" onClick={haalPostWeg}>
          Haal laatste post weg
        </button>

        <button type="submit">Opsturen</button>
      </form>
    </div>
  );
}

export default DeclaForm;
